<template>
  <vx-card>
    <form-wizard
        color="rgba(var(--vs-primary), 1)"
        :hide-buttons="true"
        title="MENÚ"
        subtitle="Por favor complete los campos del formularios de actualización de menú"
    >
      <form data-vv-scope="general-data" @submit.prevent>
        <vs-divider class="vx-col w-full mt-5" position="left" color="light">Datos Generales</vs-divider>
        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/3 ">
            <label for="name" class="w-full select-large">Nombre</label>
            <vs-input
                id="name" name="name" v-model="entryModel.name"
                class="w-full" v-validate="'required'" placeholder="Nombre del menú"
            />
            <span class="text-danger">{{ errors.first("general-data.name") }}</span>
          </div>

          <div class="vx-col w-full lg:w-1/3 ">
            <label for="institution" class="w-full select-large">Institución</label>
            <v-select
              id="institution" name="institution" label="name" placeholder="Seleccione..."
              v-model="entryModel.institution" :options="institutions" v-validate="'required'"
            />
            <span class="text-danger">{{ errors.first("general-data.institution") }}</span>
          </div>

          <div class="vx-col w-full lg:w-1/3 ">
            <label for="name" class="w-full select-large">Tipo de dispositivo</label>
            <v-select
              name="device_type" id="device_type" placeholder="Seleccione..." :options="devices"
              v-model="entryModel.device_type" v-validate="'required'" :reduce="(deviceType) => deviceType.value"
            />
          </div>
        </div>

        <vs-divider class="vx-vol w-full mt-5" position="left" color="light">Configuración</vs-divider>
        <collapsable-card
          v-for="(component, index) in entryModel.components" :title="`${index + 1}. ${componentNamesMapper[component.type]}`"
          class="mt-10"
        >
          <template #header-actions-right>
            <vs-button icon-pack="feather" icon="icon-trash-2" type="flat" @click="removeComponent(index)"/>
          </template>

          <div class="p-5">
            <component
              :is="component.type" v-model="entryModel.components[index]" :institution="entryModel.institution.id"
            />
          </div>
        </collapsable-card>

        <vs-divider class="vx-col w-full mt-5" position="left" color="light" v-if="entryModel.components.length !== 0"/>

        <div class="vx-row">
          <menu-component-selector class="vx-col md:w-1/3 w-1/2" mode="all" @input="addComponent($event)"/>
        </div>

        <vs-divider class="vx-col w-full mt-5" position="left" color="light" v-if="entryModel.components.length !== 0"/>

        <div class="vx-row mt-5">
          <div class="vx-col w-full">
            <span class="text-danger" v-if="entryModel.components.length === 0 && showError">
              Por favor complete todos los datos del formulario y seleccione al menos un componente
            </span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full mt-5">
            <div class="flex flex-wrap justify-between">
              <vs-row vs-type="flex" vs-justify="flex-end">
                <vs-button color="danger" type="border" class="mb-2" href="/menus">Cancelar</vs-button>
                <vs-button class="ml-3 mb-2" @click="createOrUpdateEntry">Guardar</vs-button>
              </vs-row>
            </div>
          </div>
        </div>
      </form>
    </form-wizard>
  </vx-card>
</template>

<script>
  import vSelect from "vue-select";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";

  import CollapsableCard from "@/views/menus/components/CollapsableCard.vue";
  import CoreClient from "@/utils/coreClient";
  import MenuComponentSelector from "@/views/menus/components/MenuComponentSelector.vue";
  import Notifier from "@/utils/notification";
  import {ComponentsNameMapper} from "@/views/menus/utils/constants";
  import { EventBus } from "@/utils/event-bus";
  import { FormWizard } from 'vue-form-wizard';


  const institutionsClient = new CoreClient("institutions");
  const menusClient = new CoreClient("menus");
  const notifier = new Notifier();


  export default {
    name: "CUEntryForm",

    components: {
      CollapsableCard,
      FormWizard,
      MenuComponentSelector,
      vSelect,
    },

    data() {
      return {
        devices: [
          {
            label:  "Escritorio",
            value: "desktop",
          },
          {
            label: "Móvil",
            value: "mobile",
          }
        ],
        entryModel: {
          name: '',
          institution: '',
          components: [],
        },
        institutions: [],
        validationStates: [],
        showError: false,
      }
    },

    computed: {
      allFormValid(){
        if (this.validationStates.length > 0) {
          return this.validationStates.every((value) => value === true);
        }
        return false
      },

      componentNamesMapper(){
        return ComponentsNameMapper;
      },
    },

    async created() {
      EventBus.$on("validation-state", (data) => {
        this.validationStates.push(data);
      });

      try{
        this.$vs.loading();

        const institutionsResponse = await institutionsClient.all({
          queryStrings: {
            stage: "staging"
          }
        });
        this.institutions = institutionsResponse.data;

        if (this.$route.params.id) {
          const menuResponse = await menusClient.readMenu({pathParameters: {id: this.$route.params.id}});
          this.entryModel = menuResponse.data;
        }
        this.$vs.loading.close();
      } catch (_) {
        this.$vs.loading.close();
        await notifier.notification('error');
      }
    },

    methods: {
      addComponent(componentInfo) {
        this.entryModel.components.push({type: componentInfo.value})
      },

      async createOrUpdateEntry() {
        this.validationStates = [];
        EventBus.$emit("validate-fields")
        const isFormValid = await this.$validator.validateAll("general-data");

        if (isFormValid && this.allFormValid && this.entryModel.components.length !== 0) {
          try{
            this.$vs.loading();

            if (this.entryModel.id) {
              await menusClient.updateMenu({
                pathParameters:{id: this.entryModel.id},
                data: this.entryModel,
              });
            } else {
              await menusClient.createMenu({data: this.entryModel});
            }

            this.$vs.loading.close();
            await this.$router.push({ name: "menus" });
            await notifier.notification(this.entryModel.id ? "updated":"created");
          } catch(_) {
            this.$vs.loading.close();
            await notifier.notification('error');
          }
        } else {
          this.showError = true;
        }
      },

      removeComponent(index) {
        this.entryModel.components.splice(index, 1);
      },
    }
  }
</script>
